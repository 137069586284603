import React from 'react';
import Layout from '../components/layout';
import Code from '../components/auth/code';
import { pageview } from '../utils/scripts';
import SEO from '../components/seo';

class SigninPage extends React.Component {
  componentDidMount() {
    pageview();
  }

  render() {
    return (
      <Layout>
        <SEO title="Sign-in" />
        <section className="">
          <div className="container px-5 py-12 mx-auto flex flex-wrap items-center">
            <div className="lg:w-1/2 md:w-1/2 flex flex-col md:mx-auto w-full f">
              <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900 font-headline">
                  Login with your mobile phone
                </h2>
              </div>
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-100">
                <Code />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default SigninPage;
